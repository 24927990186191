import _get from 'lodash/get'
import _pick from 'lodash/pick'
import axios from 'axios'
import moment from 'moment'
/* eslint-disable import/no-cycle */
import store from '@/store'

function constructQuery(params) {
  const searchParams = new URLSearchParams(params)
  return `?${searchParams.toString()}`
}

function processError(error, callerName, redirect = true) {
  const { response, request, message, stack } = error

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(
      'Error: the server responded with a status code that falls out of 2xx range',
      _pick(response, ['data', 'status', 'headers'])
    )
    console.warn(error)
  } else if (request) {
    console.log(
      'Error: the request was made, but no response was received.',
      request
    )
  } else {
    console.log(
      'Error: something went wrong while setting up the request.',
      message
    )
  }
  console.log('Error stack:')
  console.log(stack)
  console.log(`Error Caller Name: ${callerName}`)

  if (redirect) {
    // NOTE: This is the cause of infinite request loop on wprkplace page when backend is broken
    // If token is expired redirect to login page
    if (error?.response?.status === undefined)
      setTimeout(() => {
        window.location.replace('/workplace')
      }, 60000)
  }
}

function guessReject(error) {
  const { response, message } = error

  if (response) {
    const { data, headers } = response
    if (
      _get(headers, '["content-type"]', '').indexOf(
        'application/json'
      ) !== -1
    ) {
      return data.message
    }
    return data
  }
  return message || 'Error: something went wrong'
}

class OkkuAPI {
  constructor() {
    this._API_HOST =
      process.env.VUE_APP_OKKU_API_HOST ||
      `${window.location.protocol}//${window.location.host}`
  }

  getApiHost() {
    return this._API_HOST
  }

  async getMyBuildings() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/my/buildings`
      )
      return data
    } catch (error) {
      processError(error, 'getMyBuildings')
      return []
    }
  }

  async getUserById(id) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/users${store.state.common.$organisation}/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'getUserById')
      return {}
    }
  }

  async canEditUser(id) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/users/${id}/canEdit`
      )
      return data
    } catch (error) {
      processError(error, 'canEditUser')
      return {}
    }
  }

  async getUserGroups(id) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/users/${id}/groups`
      )
      return data
    } catch (error) {
      processError(error, 'getUserGroups')
      throw _get(error, 'response.data', {
        message: 'An error occured'
      })
    }
  }

  async addUserToGroup(userId, groupId) {
    try {
      const { data } = await axios.put(
        `${this._API_HOST}/api/3.0/admin/users/${userId}/groups/${groupId}`
      )
      return data
    } catch (error) {
      processError(error, 'addUserToGroup')
      throw guessReject(error)
    }
  }

  async removeUserFromGroup(userId, groupId) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/users/${userId}/groups/${groupId}`
      )
      return data
    } catch (error) {
      processError(error, 'removeUserFromGroup')
      throw guessReject(error)
    }
  }

  async removeUser(userId) {
    if (!userId) {
      throw new Error('User ID required')
    }
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/users/${userId}`
      )
      return data
    } catch (error) {
      processError(error, 'removeUser')
      return {}
    }
  }

  async updateUser(user) {
    if (!user.id) {
      throw new Error('User ID required')
    }
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/users/${user.id}`,
        user
      )
      return data
    } catch (error) {
      processError(error, 'updateUser')
      return {}
    }
  }

  async getBuildingUsers(search) {
    try {
      const query = search ? `?search=${search}` : ''
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/users${store.state.common.$organisation}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getBuildingUsers')
      return []
    }
  }

  async getOrgUsers(search, page) {
    try {
      const org = _get(
        store.state.common.$organisation.split('/'),
        '[1]'
      )

      const query = search ? `?search=${search}&page=${page}` : ''
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/users/${org}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getOrgUsers')
      return []
    }
  }

  /**
   *
   * @returns list of fixed desks. Access is restricted to Admins and Coordinators
   */
  async getFixedDeskUsers() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/desks${store.state.common.$organisation}/fixedDeskUsers`
      )
      return data
    } catch (error) {
      processError(error, 'getFixedDeskUsers')
      return []
    }
  }

  /**
   *
   * @returns a list of fixed desks, Access restricted to Insights role and Admins/coordinators
   */
  async getInsightsFixedDeskUsers() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/fixedDeskUsers${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'getInsightsFixedDeskUsers')
      return []
    }
  }

  async getAbsences(user) {
    try {
      const path = user ? `${user}` : ''
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/plannedAbsences${store.state.common.$organisation}/${path}`
      )
      return data
    } catch (error) {
      processError(error, 'getAbsences')
      return []
    }
  }

  async createAbsence(user, params) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/plannedAbsences${store.state.common.$organisation}/${user}`,
        params
      )
      return data
    } catch (error) {
      processError(error, 'createAbsence')
      throw error
    }
  }

  async updateAbsences(absences) {
    try {
      const {
        data
      } = await axios.put(
        `${this._API_HOST}/api/3.0/plannedAbsences${store.state.common.$organisation}`,
        { absences }
      )
      return data
    } catch (error) {
      processError(error, 'updateAbsences')
      throw error
    }
  }

  async deleteAbsence(user, createdTime) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/plannedAbsences${store.state.common.$organisation}/${user}/${createdTime}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteAbsence')
      throw error
    }
  }

  async getDesks(building = store.state.common.$organisation) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/desks${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getDesks')
      return []
    }
  }

  async getDeskStatus(query = '') {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/desks${store.state.common.$organisation}/deskStatus${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getDeskStatus')
      return []
    }
  }

  async createDesks(params) {
    return this.updateDesks(params)
  }

  async updateDesks(params) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/desks${store.state.common.$organisation}`,
        params
      )
      return data
    } catch (error) {
      processError(error, 'updateDesks')
      throw error
    }
  }

  async deleteDesk(id) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/desks${store.state.common.$organisation}/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteDesk')
      throw error
    }
  }

  async getAuthorisations() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/buildings${store.state.common.$organisation}/access`
      )
      return data
    } catch (error) {
      processError(error, 'getAuthorisations')
      throw error
    }
  }

  async upsertAuthorisation(identifier, description) {
    const searchParams = new URLSearchParams({
      identifier,
      description
    })
    const query = `?${searchParams.toString()}`
    try {
      const { data } = await axios.put(
        `${this._API_HOST}/api/3.0/admin/buildings${store.state.common.$organisation}/access${query}`
      )
      return data
    } catch (error) {
      processError(error, 'upsertAuthorisation')
      throw error
    }
  }

  async deleteAuthorisation(identifier) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/buildings${store.state.common.$organisation}/access/${identifier}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteAuthorisation')
      throw error
    }
  }

  async getMyFloors(building = store.state.common.$organisation) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/floors${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getMyFloors')
      return []
    }
  }

  async getFloors() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/floors${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'getFloors')
      return []
    }
  }

  async upsertFloors(params) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/floors${store.state.common.$organisation}`,
        params
      )
      return data
    } catch (error) {
      processError(error, 'upsertFloors')
      throw error
    }
  }

  async upsertFloorsWithRestrictions(params) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/floors${store.state.common.$organisation}/withRestrictions`,
        params
      )
      return data
    } catch (error) {
      processError(error, 'upsertFloorsWithRestrictions')
      throw error
    }
  }

  async deleteFloor(id) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/floors${store.state.common.$organisation}/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteFloor')
      throw error
    }
  }

  async getSensors() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/sensors${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'getSensors')
      return []
    }
  }

  async getSensorTypes() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/sensors/types`
      )
      return data
    } catch (error) {
      processError(error, 'getSensorTypes')
      return []
    }
  }

  async getSensor(sensorId) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/sensors${store.state.common.$organisation}/${sensorId}`
      )
      return data
    } catch (error) {
      processError(error, 'getSensor')
      return []
    }
  }

  async createSensor(sensors) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/sensors${store.state.common.$organisation}`,
        sensors
      )
      return data
    } catch (error) {
      processError(error, 'createSensor')
      throw error
    }
  }

  async deleteSensor(sensorId) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/sensors${store.state.common.$organisation}/${sensorId}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteSensor')
      throw error
    }
  }

  async updateSensor(sensors) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/sensors${store.state.common.$organisation}`,
        sensors
      )
      return data
    } catch (error) {
      processError(error, 'updateSensor')
      throw error
    }
  }

  async getRooms(building = store.state.common.$organisation) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/rooms${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getRooms')
      return []
    }
  }

  async getRoomStatus(query) {
    query = query || ''
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/rooms${store.state.common.$organisation}/roomStatus${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getRoomStatus')
      return []
    }
  }

  async createRooms(rooms) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/rooms${store.state.common.$organisation}`,
        rooms
      )
      return data
    } catch (error) {
      processError(error, 'createRooms')
      throw error
    }
  }

  async updateRooms(rooms) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/rooms${store.state.common.$organisation}`,
        rooms
      )
      return data
    } catch (error) {
      processError(error, 'updateRooms')
      throw error
    }
  }

  async deleteRoom(roomId, roomEmail) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/rooms${store.state.common.$organisation}/${roomId}/${roomEmail}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteRoom')
      throw error
    }
  }

  async getSettings(orgPath) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/buildings${orgPath}/settings`
      )
      return data
    } catch (error) {
      processError(error, 'getSettings')
      return []
    }
  }

  async getReservations(
    from,
    email,
    page,
    perPage,
    search,
    orderBy,
    orderByDirection,
    to = null,
    filters = {}
  ) {
    const searchParams = new URLSearchParams()

    // eslint-disable-next-line
    for (const key in filters) searchParams.append(key, filters[key])

    if (from) {
      searchParams.append('from', from)
    }

    if (to) {
      searchParams.append('to', to)
    }

    if (!Number.isNaN(page) && !Number.isNaN(perPage)) {
      searchParams.append('page', page)
      searchParams.append('perPage', perPage)
    }

    if (search) {
      searchParams.append('search', search)
    }
    if (orderBy) {
      searchParams.append('orderBy', orderBy)
      searchParams.append(
        'orderByDirection',
        ['ASC', 'DESC'].indexOf(orderByDirection) !== -1
          ? orderByDirection
          : 'ASC'
      )
    }

    const query = `?${searchParams.toString()}`
    const subpath = email ? `/${email}` : ''

    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}${subpath}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getReservations')
      return []
    }
  }

  async getTodaysReservationsForObject(objectId, date) {
    try {
      const data = await axios.get(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}/${objectId}/daySchedule?date=${date}`
      )
      return data
    } catch (error) {
      processError(error, 'getTodaysReservationsForObject')
      return []
    }
  }

  async getDailyScreenReservations(
    org,
    orgPath,
    floors,
    showDesks,
    page,
    pageSize
  ) {
    const queryParams = new URLSearchParams()
    floors.forEach(floorId => {
      if (!floorId) return
      queryParams.append('floors', floorId)
    })
    if (showDesks) queryParams.append('showDesks', showDesks)
    if (page) queryParams.append('page', page)
    if (pageSize) queryParams.append('pageSize', pageSize)

    const baseUrl = `${this._API_HOST}/api/3.0/dailyReservations/${org}/${orgPath}`

    try {
      const data = await axios.get(
        baseUrl + (queryParams ? `?${queryParams.toString()}` : '')
      )

      return data
    } catch (error) {
      processError(error, 'getDailyScreenReservations', false)
      return []
    }
  }

  async getColleagues(
    from,
    page,
    perPage,
    search,
    orderBy,
    orderByDirection
  ) {
    const searchParams = new URLSearchParams()

    if (from) {
      searchParams.append('from', from)
    }

    if (!Number.isNaN(page) && !Number.isNaN(perPage)) {
      searchParams.append('page', page)
      searchParams.append('perPage', perPage)
    }

    if (search) {
      searchParams.append('search', search)
    }
    if (orderBy) {
      searchParams.append('orderBy', orderBy)
      searchParams.append(
        'orderByDirection',
        ['ASC', 'DESC'].indexOf(orderByDirection) !== -1
          ? orderByDirection
          : 'ASC'
      )
    }

    const query = `?${searchParams.toString()}`
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/my${store.state.common.$organisation}/colleagues${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getColleagues')
      return {
        reservations: [],
        count: 0
      }
    }
  }

  async reserveObject(
    query,
    invitedUsers,
    reservedForEmail,
    reservationAssets
  ) {
    try {
      const {
        data
      } = await axios.post(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}/${query}`,
        { invitedUsers, reservedForEmail, reservationAssets }
      )
      return data
    } catch (error) {
      processError(error, 'reserveObject')
      throw error
    }
  }

  async cancelReservation(hash) {
    const query = `?hash=${hash}`

    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'cancelReservation')
      throw error.response
    }
  }

  async checkInReservationWithQrCode(buildingId, verificationCode) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/qr/check-in/${buildingId}/${verificationCode}`
      )
      return data
    } catch (error) {
      processError(error, 'checkInReservationWithQrCode')
      throw error.response
    }
  }

  async checkInReservation(id) {
    try {
      const { data } = await axios.patch(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}/${id}/check-in`
      )
      return data
    } catch (error) {
      processError(error, 'checkInReservation')
      throw error.response
    }
  }

  async getStateMachines() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/buildings/stateMachines`
      )
      return data
    } catch (error) {
      processError(error, 'getStateMachines')
      return []
    }
  }

  async getBuildings() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/buildings`
      )
      return data
    } catch (error) {
      processError(error, 'getBuildings')
      return []
    }
  }

  async getOrganisations() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/organisations`
      )
      return data
    } catch (error) {
      processError(error, 'getOrganisations')
      return []
    }
  }

  async deleteOrgMappings(orgMappingId) {
    try {
      const data = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/organisations/${orgMappingId}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteOrgMappings')
      return error
    }
  }

  async upsertOrganisation(organisation) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/organisations`,
        organisation
      )
      return data
    } catch (error) {
      processError(error, 'upsertOrganisation')
      throw new Error(error)
    }
  }

  async exportBuilding(id) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/buildings/export/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'exportBuilding')
      return []
    }
  }

  async importBuilding(formData) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/buildings/import`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return data
    } catch (error) {
      processError(error, 'importBuilding')
      throw error
    }
  }

  async upsertBuilding(building) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/buildings`,
        building
      )
      return data
    } catch (error) {
      processError(error, 'upsertBuilding')
      throw error.response
    }
  }

  async deleteBuilding(orgId, recursive) {
    const query = recursive ? '?recursive=1' : ''

    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/buildings/${orgId}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteBuilding')
      throw error
    }
  }

  async getAvailableTimeslots(
    orgPath,
    date = moment()
      .tz('UTC')
      .startOf('day')
      .toISOString()
  ) {
    const query = constructQuery({
      date
    })
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/timeslots/${orgPath}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getAvailableTimeslots')
      return []
    }
  }

  async getTimeslots() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'getTimeslots')
      return []
    }
  }

  async upsertTimeslot(timeslot) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}`,
        timeslot
      )
      return data
    } catch (error) {
      processError(error, 'upsertTimeslot')
      if (error && error.response) {
        throw error.response
      } else {
        throw error
      }
    }
  }

  async deleteTimeslot(id) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteTimeslot')
      return []
    }
  }

  async getOverLappedRoomsAndSlots({ from, to }) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}/split/${from}/${to}`
      )
      return data
    } catch (error) {
      processError(error, 'getOverLappedRoomsAndSlots')
      return []
    }
  }

  async getReservationByHash(hash) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/confirmations/${hash}`
      )
      return data
    } catch (error) {
      processError(error, 'getReservationByHash')
      return null
    }
  }

  async getBuildingAvailability(org, building, queryParams) {
    const { startTime, endTime } = queryParams
    const query = constructQuery({
      from: startTime,
      to: endTime
    })

    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/buildings/${org}/${building}/availability${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getBuildingAvailability')
      throw error.response
    }
  }

  async upsertSplitSlots(slotsData) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}/split`,
        slotsData
      )
      return data
    } catch (error) {
      processError(error, 'upsertSplitSlots')
      return []
    }
  }

  async updateBuildingSetting(params) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/buildings${store.state.common.$organisation}/settings`,
        params
      )
      return data
    } catch (error) {
      processError(error, 'updateBuildingSetting')
      throw error.response
    }
  }

  async getAttributes() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/attributes${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'getAttributes')
      throw error.response
    }
  }

  async upsertAttribute(payload) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/attributes${store.state.common.$organisation}`,
        payload
      )
      return data
    } catch (error) {
      processError(error, 'upsertAttribute')
      throw error
    }
  }

  async deleteAttribute(id) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/attributes${store.state.common.$organisation}/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteAttribute')
      throw error
    }
  }

  async updateAttributeAssociation(payload) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/attributes${store.state.common.$organisation}/associate`,
        payload
      )
      return data
    } catch (error) {
      processError(error, 'updateAttributeAssociation')
      throw error
    }
  }

  async getEmailTemplates() {
    try {
      const org = _get(
        store.state.common.$organisation.split('/'),
        '[1]'
      )
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/emailTemplates/${org}`
      )
      return data
    } catch (error) {
      processError(error, 'getEmailTemplates')
      return []
    }
  }

  async upsertEmailTemplate(template) {
    try {
      const org = _get(
        store.state.common.$organisation.split('/'),
        '[1]'
      )
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/emailTemplates/${org}`,
        template
      )
      return data
    } catch (error) {
      processError(error, 'upsertEmailTemplate')
      throw error.response
    }
  }

  async deleteEmailTemplate(id, type) {
    try {
      const org = _get(
        store.state.common.$organisation.split('/'),
        '[1]'
      )
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/emailTemplates/${org}/${id}/${type}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteEmailTemplate')
      throw error.response
    }
  }

  async getIdenticalRooms(timeslotId) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/timeslots${store.state.common.$organisation}/${timeslotId}/identical`
      )
      return data
    } catch (error) {
      processError(error, 'getIdenticalRooms')
      throw error
    }
  }

  async getGoogleRooms(id) {
    try {
      const params = id === null ? '' : id
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/rooms${store.state.common.$organisation}/googleRooms/${params}`
      )
      return data
    } catch (error) {
      processError(error, 'getGoogleRooms')
      throw error
    }
  }

  async getAzureRooms(id) {
    try {
      const params = id || ''
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/rooms${store.state.common.$organisation}/azureRooms/${params}`
      )
      return data
    } catch (error) {
      processError(error, 'getAzureRooms')
      throw error
    }
  }

  async getMonthlyReportByUser(from, to, search, allBuildings) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)

      if (search) {
        searchParams.append('search', search)
      }
      if (allBuildings) {
        searchParams.append('allBuildings', true)
      }
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/reservations${store.state.common.$organisation}/monthly-summary${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getMonthlyReportByUser')
      throw error
    }
  }

  async getWeeklyReportByUser(from, to, search, allBuildings) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)

      if (search) {
        searchParams.append('search', search)
      }
      if (allBuildings) {
        searchParams.append('allBuildings', true)
      }
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/reservations${store.state.common.$organisation}/weekly-summary${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getWeeklyReportByUser')
      throw error
    }
  }

  async getHourlyStats(from, to, allBuildings) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)
      if (allBuildings) {
        searchParams.append('allBuildings', true)
      }
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/reservations${store.state.common.$organisation}/hourly-statistics${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getHourlyStats')
      throw error
    }
  }

  async getHourlyCancellationStats(from, to, allBuildings) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)
      if (allBuildings) {
        searchParams.append('allBuildings', true)
      }
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/reservations${store.state.common.$organisation}/hourly-cancellations${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getHourlyCancellationStats')
      throw error
    }
  }

  async getReservationDetails(from, to, search, allBuildings) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)
      if (search) {
        searchParams.append('search', search)
      }
      if (allBuildings) {
        searchParams.append('allBuildings', true)
      }

      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/insights/reservations${store.state.common.$organisation}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getReservationDetails')
      throw error
    }
  }

  async getSensorHistory(from, to, sensorId) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/sensors${store.state.common.$organisation}/${sensorId}/history${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getSensorHistory')
      throw error
    }
  }

  async getClimateIndicators(
    from,
    to,
    maxCo2,
    minHum,
    minTemp,
    maxTemp
  ) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('fromDate', from)
      searchParams.append('toDate', to)

      if (maxCo2) {
        searchParams.append('maxCo2', maxCo2)
      }
      if (minHum) {
        searchParams.append('minHum', minHum)
      }
      if (minTemp) {
        searchParams.append('minTemp', minTemp)
      }
      if (maxTemp) {
        searchParams.append('maxTemp', maxTemp)
      }
      const query = `?${searchParams.toString()}`
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/sensors${store.state.common.$organisation}/climateIndicators${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getClimateIndicators')
      throw error
    }
  }

  /* unauthenticated calls */

  async getSensorData(id) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/sensors/byExternalId/${id}`
      )
      return data
    } catch (error) {
      processError(error, 'getSensorData')
      throw error.response
    }
  }

  async getDomainInformation(params) {
    try {
      let routeParams = ''
      if (params && params.org && params.building) {
        routeParams = `${params.org}/${params.building}`
      }
      if (this.idpHint || this.logoUrl)
        return {
          idpHint: this.idpHint,
          logoUrl: this.logoUrl,
          supportEmail: this.supportEmail
        }
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/domainName/${routeParams}`
      )
      this.idpHint = data.idpHint
      this.logoUrl = data?.logoUrl
      this.supportEmail = data.supportEmail
      return data
    } catch (error) {
      processError(error, 'getDomainInformation')
      throw error.response
    }
  }

  async SaveDateTimeToken(
    token,
    organization,
    organizationPath,
    floors
  ) {
    try {
      const response = await axios.post(
        `${this._API_HOST}/api/3.0/daily-screens/${organization}/${organizationPath}`,
        {
          token,
          organization,
          organizationPath,
          floors
        }
      )
      return response
    } catch (error) {
      processError(error, 'SaveDateTimeToken')
      throw error.response
    }
  }

  async getDailyReservationTokens() {
    try {
      const response = await axios.get(
        `${this._API_HOST}/api/3.0/daily-screens${store.state.common.$organisation}`
      )
      return response
    } catch (error) {
      processError(error, 'getDailyReservationTokens')
      throw error.response
    }
  }

  async deleteDailyReservationToken(token) {
    try {
      const response = await axios.delete(
        `${this._API_HOST}/api/3.0/daily-screens/deleteToken${store.state.common.$organisation}/${token}`,
        {
          token
        }
      )
      return response
    } catch (error) {
      processError(error, 'deleteDailyReservationToken')
      throw error.response
    }
  }

  async upsertTokenStatus(token, status, action) {
    try {
      const response = await axios.post(
        `${this._API_HOST}/api/3.0/daily-screens${store.state.common.$organisation}/process`,
        {
          token,
          status,
          action
        }
      )
      return response
    } catch (error) {
      processError(error, 'upsertTokenStatus')
      throw error.response
    }
  }

  async checkForTokenApproval(token, org, orgPath) {
    try {
      const response = await axios.get(
        `${this._API_HOST}/api/3.0/daily-screens/${org}/${orgPath}/checkForTokenApproval/${token}`
      )
      return response
    } catch (error) {
      processError(error, 'checkForTokenApproval', false)
      throw error.response
    }
  }

  async getBuildingThemeSettings(org, orgPath) {
    try {
      let url = ''

      if (!org && !orgPath) {
        url = `${this._API_HOST}/api/3.0/daily-screens${store.state.common.$organisation}/theme/settings`
      } else if (org && orgPath) {
        url = `${this._API_HOST}/api/3.0/daily-screens/${org}/${orgPath}/theme/settings`
      }
      const response = await axios.get(url)
      return response
    } catch (error) {
      processError(error, 'getBuildingThemeSettings', false)
      throw error.response
    }
  }

  async saveScreenThemeSettings(themeSettings) {
    try {
      const response = await axios.post(
        `${this._API_HOST}/api/3.0/daily-screens${store.state.common.$organisation}/theme/settings/save`,
        {
          themeSettings
        }
      )
      return response
    } catch (error) {
      processError(error, 'saveScreenThemeSettings')
      throw error.response
    }
  }

  async getReferralCode() {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/users/referral/code`
      )
      return data
    } catch (error) {
      processError(error, 'getReferralCode')
      throw error.response
    }
  }

  async updateReservation(reservation) {
    try {
      const { data } = await axios.put(
        `${this._API_HOST}/api/3.0/reservations${store.state.common.$organisation}/update`,
        reservation
      )
      return data
    } catch (error) {
      processError(error, 'updateReservation')
      throw error
    }
  }

  /**
   * Gets all assets types of a building
   */
  async getAssetTypes(startDate, endDate) {
    try {
      let url = `${this._API_HOST}/api/3.0/reservationAssetType${store.state.common.$organisation}`
      if (startDate && endDate) {
        url = url.concat(`?startDate=${startDate}&endDate=${endDate}`)
      }
      const { data } = await axios.get(url)
      return data
    } catch (error) {
      processError(error, 'getAssetTypes')
      throw error
    }
  }

  /**
   * Adds/edits asset type to a building
   */
  async upsertAssetType(assetType) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/reservationAssetType${store.state.common.$organisation}`,
        assetType
      )
      return data
    } catch (error) {
      processError(error, 'upsertAssetType')
      throw error
    }
  }

  /**
   * Remove asset type from a building
   */
  async deleteAssetType(assetType) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/reservationAssetType${store.state.common.$organisation}/${assetType.id}`
      )
      return data
    } catch (error) {
      processError(error, 'deleteAssetType')
      throw error
    }
  }

  async upsertReview(review) {
    try {
      ;[, review.org] = store.state.common.$organisation.split('/')
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/users/upsertReview`,
        review
      )
      return data
    } catch (error) {
      processError(error, 'upsertReview')
      throw error
    }
  }

  async getRewardsScreenVisibilityStatus(userId) {
    try {
      if (!userId) return false

      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/users/rewards/screen/status/${userId}`
      )
      return data
    } catch (error) {
      processError(error, 'getRewardsScreenVisibilityStatus')
      throw error.response
    }
  }

  async updateRewardsScreenVisibilityStatus(userId) {
    try {
      if (!userId) {
        return undefined
      }
      const { data } = await axios.put(
        `${this._API_HOST}/api/3.0/users/rewards/screen/status/${userId}`
      )
      return data
    } catch (error) {
      processError(error, 'updateRewardsScreenVisibilityStatus')
      throw error.response
    }
  }

  async addBuildingCheckInRecord() {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/qr/building/check-in${store.state.common.$organisation}`
      )
      return data
    } catch (error) {
      processError(error, 'addBuildingCheckInRecord')
      throw error.response
    }
  }

  async validateCsvFile(csvData) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/users/csv/validate`,
        csvData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return data
    } catch (error) {
      processError(error, 'validateCsvFile')
      throw error
    }
  }

  async uploadUsers(usersData, userId) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/admin/users/csv/upload/${userId}`,
        usersData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return data
    } catch (error) {
      processError(error, 'uploadUsers')
      throw error
    }
  }

  async getFileStatusList(userId) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/admin/users/upload/jobs/status/${userId}`
      )
      return data
    } catch (error) {
      processError(error, 'getFileStatusList')
      throw error
    }
  }

  async removeJobRecord(userId, jobId) {
    try {
      const { data } = await axios.delete(
        `${this._API_HOST}/api/3.0/admin/users/upload/jobs/remove/${userId}/${jobId}`
      )
      return data
    } catch (error) {
      processError(error, 'removeJobRecord')
      throw guessReject(error)
    }
  }

  async getAssetAvailabilityDetails(date) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/reservationAssetType${store.state.common.$organisation}/assets/details/${date}`
      )
      return data
    } catch (error) {
      processError(error, 'getAssetAvailabilityDetails')
      throw error
    }
  }

  //  public screens api calls

  async getPublicScreenTokens() {
    try {
      const response = await axios.get(
        `${this._API_HOST}/api/3.0/daily-screens${store.state.common.$organisation}`
      )
      return response
    } catch (error) {
      processError(error, 'getPublicScreenTokens')
      throw error.response
    }
  }

  // kiosk screen
  async getKioskToken({ org, building, floors, kioskId }) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('floors', floors)
      const response = await axios.get(
        `${
          this._API_HOST
        }/api/3.0/kiosk/${org}/${building}/${kioskId}/approval/?${searchParams.toString()}`
      )
      return response
    } catch (error) {
      processError(error, 'getKioskToken')
      throw error.response
    }
  }

  async reserveObjectForKiosk(
    query,
    invitedUsers,
    reservationAssets
  ) {
    try {
      const { data } = await axios.post(
        `${this._API_HOST}/api/3.0/publicScreens/reservations${store.state.common.$organisation}/${query}`,
        {
          invitedUsers,
          reservationAssets
        }
      )
      return data
    } catch (error) {
      processError(error, 'reserveObjectForKiosk')
      throw error
    }
  }

  async getSettingsForKiosk(orgPath) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/buildings${orgPath}/settings`
      )
      return data
    } catch (error) {
      processError(error, 'getSettingsForKiosk')
      return []
    }
  }

  async getTimeslotsForKiosk(
    orgPath,
    date = moment()
      .tz('UTC')
      .startOf('day')
      .toISOString()
  ) {
    const query = constructQuery({ date })
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/timeslots/${orgPath}${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getTimeslotsForKiosk')
      return []
    }
  }

  async getFloorsForKiosk(building) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/floors${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getFloorsForKiosk')
      return []
    }
  }

  async getRoomsForKiosk(building) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/rooms${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getRoomsForKiosk')
      return []
    }
  }

  async getDesksForKiosk(building) {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/desks${building}`
      )
      return data
    } catch (error) {
      processError(error, 'getDesksForKiosk')
      return []
    }
  }

  async getDeskStatusForKiosk(query = '') {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/desks${store.state.common.$organisation}/deskStatus${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getDeskStatusForKiosk')
      return []
    }
  }

  async getRoomStatusForKiosk(query = '') {
    try {
      const { data } = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/rooms${store.state.common.$organisation}/roomStatus${query}`
      )
      return data
    } catch (error) {
      processError(error, 'getRoomStatusForKiosk')
      return []
    }
  }

  async getTodaysReservationsForObjectForKiosk(objectId, date) {
    try {
      const data = await axios.get(
        `${this._API_HOST}/api/3.0/publicScreens/reservations${store.state.common.$organisation}/${objectId}/daySchedule?date=${date}`
      )
      return data
    } catch (error) {
      processError(error, 'getTodaysReservationsForObjectForKiosk')
      return []
    }
  }

  async logout() {
    try {
      const response = await axios.get(`${this._API_HOST}/logout`)
      console.log(JSON.stringify(response))
    } catch (error) {
      processError(error, 'logout')
    }
  }
}

const OkkuApi = new OkkuAPI()
export default OkkuApi
